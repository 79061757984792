<template>
  <div class="index-head">
    <div
      v-for="column in columns"
      :key="column.value"
      :class="column.sortable ? 'sortable' : ''"
      class="col col-head"
      @click="() => setSort(column)"
    >
      <span class="" :class="'text-grey-8'">{{ column.label }}</span>
      <q-icon :name="columnIcon(column.value)" class="sort-icon" />
    </div>
  </div>
</template>
<script>
export default {
  props: ['columns', 'value'],
  computed: {
    sort: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    columnIcon (column) {
      if (this.sort.column === column && this.sort.direction === 'asc') {
        return 'keyboard_arrow_up'
      } else if (this.sort.column === column && this.sort.direction === 'desc') {
        return 'keyboard_arrow_down'
      } else {
        return null
      }
    },
    setSort (column) {
      if (!column.sortable) return
      if (this.sort.column === column.value) {
        if (this.sort.direction === 'desc') {
          this.sort.direction = 'asc'
        } else {
          this.sort.direction = 'desc'
        }
      } else {
        this.sort.column = column.value
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.index-head
  display: grid;
  width calc(100% - 25px)
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

.col-head
  position relative
  padding 10px 12px

.col-head.sortable
  cursor pointer
  transition background-color .3s

  &:hover
    background #e1e1e1
</style>
